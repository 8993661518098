<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">隐私设置</div>
    </div>
    <div class="formBox">
      <van-form>
        <van-cell title="动态管理">
          <div class="rightBox" @click="change">
            允许所有人可看<van-switch
              v-model="checked"
              active-color="#0957C3"
            />
          </div>
        </van-cell>
        <div class="back"></div>
        <van-cell title="添加好友">
          <div class="rightBox" @click="change1">
            允许所有人添加<van-switch
              v-model="checked1"
              active-color="#0957C3"
            />
          </div>
        </van-cell>
        <van-cell title="私信/聊天">
          <div class="rightBox" @click="change2">
            允许所有人私信<van-switch
              v-model="checked2"
              active-color="#0957C3"
            />
          </div>
        </van-cell>
        <div class="back"></div>
        <van-cell title="黑名单" is-link @click="goBlacklistPage()" />
      </van-form>
    </div>
  </div>
</template>

<script>
import { Switch } from "vant";
import { setTrends, setFriend, setChat } from "../../../api/circle";
import { userInfo } from "../../../api/lookAt";
export default {
  data() {
    return {
      checked: false,
      checked1: false,
      checked2: false,
    };
  },
  mounted() {
    userInfo({ id: this.$store.state.user }).then((res) => {
      console.log(res, "用户信息");
      if (res.data.data.trendsAuth === 0) {
        this.checked = false;
      } else {
        this.checked = true;
      }
      if (res.data.data.friendAuth === 0) {
        this.checked1 = false;
      } else {
        this.checked1 = true;
      }
        if (res.data.data.chatAuth === 0) {
        this.checked2 = false;
      } else {
        this.checked2 = true;
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/setIndex" });
    },
    // 跳转黑名单1
    goBlacklistPage() {
      this.$router.push({ path: "blacklistPage" });
    },
    // 动态管理
    change() {
      let obj = { auth: 0 };
      if (this.checked) {
        obj.auth = 1;
      } else {
        obj.auth = 0;
      }
      setTrends(obj.auth).then((res) => {
        console.log(res, "------");
      });
    },
    // 添加好友
    change1() {
      let obj = { auth: 0 };
      if (this.checked1) {
        obj.auth = 1;
      } else {
        obj.auth = 0;
      }
      setFriend(obj.auth).then((res) => {
        console.log(res, "------");
      });
    },
    // 私信
    change2() {
      let obj = { auth: 0 };
      if (this.checked2) {
        obj.auth = 1;
      } else {
        obj.auth = 0;
      }
      setChat(obj.auth).then((res) => {
        console.log(res, "------");
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.formBox {
  margin-top: 32px;
}
.van-cell {
  /* line-height: 60px; */
  font-size: 28px;
  font-weight: 400;
  color: #455669;
}
.back {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.rightBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #97a4b4;
  font-size: 28px;
}
.van-switch {
  font-size: 38px;
  margin-left: 16px;
}
</style>
